// ** axios
import axios from "axios"
import toast from "react-hot-toast"

// ** default route
import { DefaultRoute } from "../router/routes"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// **Get Cookie by name
export function getCookieByName(cookiename) {
   // Get name followed by anything except a semicolon
   const cookiestring = RegExp(`${cookiename}=[^;]+`).exec(document.cookie)
   // Return everything after the equal sign, or an empty string if the cookie name not found
   return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "")
   }
// ** Axios instance
export const instance = () => {
   return axios.create({
      baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
      headers: { Authorization: `bearer ${getCookieByName("token")}` }
   })
}

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
   const today = new Date()
   return (
      /* eslint-disable operator-linebreak */
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
      /* eslint-enable */
   )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
   value,
   formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
   if (!value) return value
   return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
   const date = new Date(value)
   let formatting = { month: "short", day: "numeric" }

   if (toTimeForCurrentDay && isToday(date)) {
      formatting = { hour: "numeric", minute: "numeric" }
   }

   return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")


// ** main URL for utility functions (use with in axios to get,put and post data)
const mainUrl = `${process.env.REACT_APP_BASE_API_URL}/`

export  const logout = () => {
   document.cookie = `token=;expires=${new Date(0).toUTCString()}`
   localStorage.removeItem('user')
   window.location.href = '/login'
   // window.location.reload(false)
 }

 export const handleApiError = (error) => {
   console.warn(error)
   toast.dismiss()
   if (error.response) {
   if (error.response.status === 401) {
     logout()
   } else {
       if (error.response.data === undefined) {
         toast.error('Something went wrong, try again letter')
         // toast.error(error.message)
       } else {
         // console.log('Invalid token or expired!', error.response.data.msg.startsWith('Invalid token or expired!'))
 
         if (error.response.data.msg) {
           toast.error(error.response.data.msg)
         } else {
           // error.message === 'Request failed with status code 500' ? toast.error(error.response.data) : toast.error('ERROR')
           toast.error('Something went wrong, try again letter')
         }
   }
  }
 } else { console.log(error) }
 
 }
/**
 ** utility function for post Data
 * @param {String} value action for API
 * @param {Object} object of data for post data
 *
 */
export const postData = async (action, data) => {
   const url = mainUrl + action

   try {
      const response = await axios.post(url, data, { mode: "no-cors" })
      return { succeed: true, data: response.data }
   } catch (err) {
      handleApiError(err)
      console.log(err)
      return { succeed: false, data: err.response.data }
   }
}

/**
 ** utility function for getData
 **
 */
export const dataGet = async (action) => {
   const url = mainUrl + action

   try {
      const response = await axios.get(url, { headers: { Authorization: `bearer ${getCookieByName("token")}` } })
      return response.data
   } catch (err) {
      handleApiError(err)
      // return { succeed: false, data: err.response.data }
   }
}

/**
 ** utility function for get Data status wise form api
 * @param {String} value of status for get that status data
 * @param {string} value of token for get data
 */
export const orderHandler = async (status, token) => {
   try {
      const orderData = await dataGet(`live-orders-kitchen/${status}`, token)
      return orderData?.DATA || []
         
   } catch (err) {
handleApiError(err)      
   }
}

/**
 ** utility function for  put data (for update data in backend)
 * @param {String} value of action for put data
 * @param {object} value of data to update data at backend side
 * @param {string} value of token for get data
 */
export const putData = async (action, data) => {
   const config = {
      method: "put",
      url: mainUrl + action,
      headers: { Authorization: `bearer ${getCookieByName("token")}`, "Content-Type": "application/json" },
      data
   }
   try {
      const response = await axios(config)
      return { succeed: true, data: response.data }
   } catch (err) {

      handleApiError(err)
      return { succeed: false, data: err.response.data }
   }
}

export const putPasswordData = async (action, data, token) => {
   console.log(data)

   const config = {
      method: "put",
      url: `${mainUrl}${action}${token} `,
      headers: { "Content-Type": "application/json", mode: "no-cors" },
      data
   }
   console.log(config.url)
   try {
      const response = await axios(config)
      return { succeed: true, data: response.data }
   } catch (err) {
      handleApiError(err)
      console.log(err)
      return { succeed: false, data: err.response.data }
   }
}

export const uploadImage = async (file) => {
   const newInstance = instance()
   let img_id = ""
   try {
      const j = await newInstance.post(
         "/uploadImage",
         { file },
         {
            headers: {
               "Content-Type": "multipart/form-data"
            }
         }
      )
      console.log(j)
      console.log(j.data.id)
      img_id = `${j.data.id}`
   } catch (err) {
      console.warn(err)
   }
   return img_id
}
// Get image from cloud flare using image id
// export const getImageCloudFlare = (image_id, quality = process.env.REACT_APP_CLOUD_IMAGE_Default_Extention) => {
   // return `${process.env.REACT_APP_CLOUD_IMAGE_BASE_URL}/${image_id}/${quality}`
//  }

export const getImageCloudFlare = (image_id, quality = process.env.REACT_APP_CLOUD_IMAGE_Default_Extention) => {
   return `${process.env.REACT_APP_CLOUD_IMAGE_BASE_URL}/${image_id}/${quality}`
 }


/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
   if (userRole === "admin") return DefaultRoute
   if (userRole === "client") return "/access-control"
   return "/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
   ...theme,
   colors: {
      ...theme.colors,
      primary25: "#7367f01a", // for option hover bg-color
      primary: "#7367f0", // for selected option bg-color
      neutral10: "#7367f0", // for tags bg-color
      neutral20: "#ededed", // for input border-color
      neutral30: "#ededed" // for input hover border-color
   }
})
/**
 ** get user data from local storage
 */
 export const getUserData = () => JSON.parse(localStorage.getItem("user"))
