// ** React Imports
import { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute"
import PrivateRoute from "@components/routes/PrivateRoute"

// ** Utils
import { isObjEmpty } from "@utils"
// import { getUserData } from "../../utility/Utils"

const getLayout = {
   blank: <BlankLayout />,
   vertical: <VerticalLayout />,
   horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = "Eats"

// ** Default Route
const DefaultRoute = "/orders"

const Profile = lazy(() => import("../../views/Profile"))
const Orders = lazy(() => import("../../views/Orders/Orders"))
const ChangePassword = lazy(() => import("../../views/ChangePassword"))
const Login = lazy(() => import("../../views/Auth/Login"))
const ForgotPassword = lazy(() => import("../../views/Auth/ForgotPassword"))
const EmailSent = lazy(() => import("../../views/Auth/EmailSent"))
const PasswordResetSuccess = lazy(() => import("../../views/Auth/PasswordResetSuccess"))
const ResetPassword = lazy(() => import("../../views/Auth/ResetPassword"))
const NotAuthorized = lazy(() => import("../../views/NotAuthorized"))
const Error = lazy(() => import("../../views/Error"))

const getHomeRoute = () => {
   const user = JSON.parse(localStorage.getItem("user"))

   if (user) {
      return DefaultRoute
   } else {
      return "/login"
   }
}

// ** Merge Routes
const Routes = [
   {
      path: "/",
      index: true,
      element: <Navigate replace to={getHomeRoute()} />,
      meta: {
         layout: "blank"
      }
   },
   {
      path: "/login",
      element: <Login />,
      meta: {
         layout: "blank",
         publicRoute: true,
         restricted: true
      }
   },
   {
      path: "/forgot-password",
      element: <ForgotPassword />,
      meta: {
         layout: "blank",
         publicRoute: true,
         restricted: true
      }
   },
   {
      path: "/reset-password",
      element: <ResetPassword title={"Reset Password"} />,
      meta: {
         layout: "blank",
         publicRoute: true
      }
   },
   {
      path: "/onboard",
      element: <ResetPassword title={"Create Password"} />,
      meta: {
         layout: "blank",
         publicRoute: true
      }
   },
   {
      path: "/email-sent",
      element: <EmailSent />,
      meta: {
         layout: "blank",
         publicRoute: true
      }
   },
   {
      path: "/password-reset-success",
      element: <PasswordResetSuccess />,
      meta: {
         layout: "blank",
         publicRoute: true
      }
   },
   {
      path: "/not-authrize",
      element: <NotAuthorized />,
      meta: {
         layout: "blank",
         publicRoute: true
      }
   },
   {
      path: "/profile",
      element: <Profile />
   },
   {
      path: "/orders",
      element: <Orders />,
      permissions:["allow_order"]
   },
   {
      path: "/profile/change-password",
      element: <ChangePassword />
   },
   {
      path: "/*",
      element: <Error />,
      meta: {
         layout: "blank"
      }
   }
]

const getRouteMeta = (route) => {
   if (isObjEmpty(route.element.props)) {
      if (route.meta) {
         return { routeMeta: route.meta }
      } else {
         return {}
      }
   }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
   const LayoutRoutes = []

   if (Routes) {
      Routes.filter((route) => {
         let isBlank = false
         // ** Checks if Route layout or Default layout matches current layout
         if (
            (route.meta && route.meta.layout && route.meta.layout === layout) ||
            ((route.meta === undefined || route.meta.layout === undefined) &&
               defaultLayout === layout)
         ) {
            let RouteTag = PrivateRoute
            // const RouteTag = PublicRoute

            // ** Check for public or private route
            if (route.meta) {
               route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
               RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
            }
            if (route.element) {
               const Wrapper =
                  // eslint-disable-next-line multiline-ternary
                  isObjEmpty(route.element.props) && isBlank === false
                     ? // eslint-disable-next-line multiline-ternary
                       LayoutWrapper
                     : Fragment

               route.element = (
                  <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
                     <RouteTag route={route}>{route.element}</RouteTag>
                  </Wrapper>
               )
            }

            // Push route to LayoutRoutes
            LayoutRoutes.push(route)
         }
         return LayoutRoutes
      })
   }
   return LayoutRoutes
}

const getRoutes = (layout) => {
   const defaultLayout = layout || "vertical"
   const layouts = ["vertical", "horizontal", "blank"]

   const AllRoutes = []

   layouts.forEach((layoutItem) => {
      const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

      AllRoutes.push({
         path: "/",
         element: getLayout[layoutItem] || getLayout[defaultLayout],
         children: LayoutRoutes
      })
   })
   return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
