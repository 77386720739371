// ** React Imports
import { Link } from "react-router-dom"

// ** Custom Components
import Avatar from "@components/avatar"

// ** Third Party Components
import { User, Power } from "react-feather"

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.png"

// ** react
import { useEffect } from "react"

// ** authcontext
import { getImageCloudFlare } from "../../../../utility/Utils"
import { runOneSignal } from "../../../../utility/one-signal/onesignal"
import { useDispatch, useSelector } from "react-redux"
import { GetUserDetail } from "../../../../redux/authrization"
// import { defaultAvatar } from "../../../../assets/images/Icon"

// ** main component
const UserDropdown = () => {
   const dispatch = useDispatch()
   const profile = useSelector((state) => state.authrization.profile)

   const token = document.cookie.split("=")[1]

   const logoutHandler = () => {
      localStorage.clear()
      document.cookie = `token=`
   }


   useEffect(() => {
      if (token) {
         dispatch(GetUserDetail())
         runOneSignal()
      }

      return () => { }
   }, [])

   return (
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
         <DropdownToggle
            href="/"
            tag="a"
            className="nav-link dropdown-user-link"
            onClick={(e) => e.preventDefault()}
         >
            <div className="user-nav d-sm-flex d-none">
               <span className="user-name fw-bold">
                  {`${profile?.first_name || ""} `} &nbsp;
                  {`${profile?.last_name}`}
               </span>
               <span className="user-status">Kitchen manager</span>
            </div>
            <Avatar
               img={profile?.profile_pic_image_id ? getImageCloudFlare(profile?.profile_pic_image_id, process.env.REACT_APP_CLOUD_IMAGE_Avtar_Extention) : defaultAvatar}
               color="info"
               content="LD"

            />
         </DropdownToggle>
         <DropdownMenu end>
            <DropdownItem tag={Link} to="/profile">
               <User size={14} className="me-75" />
               <span className="align-middle">Profile</span>
            </DropdownItem>

            <DropdownItem divider />

            <DropdownItem tag={Link} to="/login" onClick={logoutHandler}>
               <Power size={14} className="me-75" />
               <span className="align-middle">Logout</span>
            </DropdownItem>
         </DropdownMenu>
      </UncontrolledDropdown>
   )
}

export default UserDropdown
